<template>
    <Toast />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                <h5>Client Employees</h5>
            </div>
            <div class="card">
                <div class="p-d-flex p-ai-center p-jc-between p-flex-column p-flex-lg-row p-mb-1">
                    <div class="p-d-flex ">
                        <Dropdown v-model="client_name" :options="clientList" @change="getTabData($event)" optionLabel="label"
                            appendTo="body" class="p-text-capitalize" placeholder="Select client" :style="{minWidth: '200px'}" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <TabView v-if="userdatadisplayflag">
        <TabPanel header="User Details">
            <div class="card">
                <DataTable :value="UserDetailsList" :lazy="true" :paginator="true" :rows="30" :totalRecords="UsertotalRecords"
                    :loading="loading" @page="onPageUser" class="p-datatable-users" data-key="ak1" :rowHover="true"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :style="{ 'overflow-wrap': 'break-word' }">
                    <template #empty> No data found. </template>
                    <template #loading> Loading data. Please wait... </template>
                    <Column header="Sr." headerStyle="width: 4%">
                        <template #body="{ index }">
                            <div>{{ genrateSrNo(index) }}</div>
                        </template>
                    </Column>
                    <Column header="User Name" headerStyle="width: 40%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <span>{{ data.ak5 }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Email Id" headerStyle="width: 17%">
                        <template #body="{ data }">
                            <span>{{ data.ak23 ? data.ak23 : '-' }}</span>
                        </template>
                    </Column>
                    <Column header=" Contact Number" headerStyle="width: 10%">
                        <template #body="{ data }">
                            <span>{{ data.ak24 }}</span>
                        </template>
                    </Column>
                    <Column header="Status" headerStyle="width: 6%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <div v-if="data.ak82 == 1"> Active </div>
                                <div v-else-if="data.ak82 == 2"> Temporary suspended </div>
                                <div v-else> Permanently suspended</div>
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </TabPanel>
        <TabPanel header="Karyakarta Details">
            <div class="card">
                <DataTable :value="KarykartaDetailsList" :lazy="true" :paginator="true" :rows="30"
                    :totalRecords="totalRecords" :loading="loading" @page="onPageKaryakartas" class="p-datatable-users"
                    data-key="bd1" :rowHover="true" 
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    :style="{ 'overflow-wrap': 'break-word' }">
                    <template #empty> No data found. </template>
                    <template #loading> Loading data. Please wait... </template>
                    <Column header="Karykarta Name" headerStyle="width: 40%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <span>{{ data.ak5 }}</span>
                            </div>
                        </template>
                    </Column>
                    <Column header="Email Id" headerStyle="width: 17%">
                        <template #body="{ data }">
                            <span>{{ data.ak23 ? data.ak23 : '-' }}</span>
                        </template>
                    </Column>
                    <Column header=" Contact Number" headerStyle="width: 10%">
                        <template #body="{ data }">
                            <span>{{ data.ak24 }}</span>
                        </template>
                    </Column>
                    <Column header="Status" headerStyle="width: 6%">
                        <template #body="{ data }">
                            <div class="p-text-capitalize">
                                <div v-if="data.ak82 == 1"> Active </div>
                                <div v-else-if="data.ak82 == 2"> Temporary suspended </div>
                                <div v-else> Permanently suspended</div>
                            </div>
                        </template>
                    </Column>
                </DataTable>
                <Toast />
            </div>
        </TabPanel>
    </TabView>
</template>
<script>
import ApiService from '../service/ApiService';

export default {
    data() {
        return {
            clientList: [],
            page_no: 0,
            UsertotalRecords:0,
            totalRecords: 0,
            loading: false,
            showLoader: false,
            UserDetailsList: [],
            KarykartaDetailsList: [],
            client_name: '',
            userdatadisplayflag: false,
            clientId:'',
        };
    },
    ApiService: null,
    created() {
        this.ApiService = new ApiService();
    },
    mounted() {
        this.getClientListOnly();
    },
    methods: {
        getClientListOnly(e) {
            this.ApiService.getClientListOnly(e).then((data) => {
                if (data.status == 200) {
                    this.clientList = data.data;
                } else {
                    this.clientList = null;
                }
            });
        },
        getClientKarykartaDetails() {
            this.loading = true;
            this.ApiService.getClientKarykartaDetails({ clientId: this.clientId, page_no: this.page_no }).then((data) => {
                if (data.success === true) {
                    this.KarykartaDetailsList = data.data;
                    this.totalRecords = data.count;
                    this.loading = false;
                } else {
                    this.totalRecords = 0;
                    this.KarykartaDetailsList = []
                }
            });
        },
        getClientUsersDetails() {
            this.loading = true;
            this.ApiService.getClientUsersDetails({ clientId: this.clientId, page_no: this.page_no }).then((data) => {
                if (data.success === true) {
                    this.UserDetailsList = data.data;
                    this.UsertotalRecords = data.count;
                    this.loading = false;
                } else {
                    this.UserDetailsList = [];
                    this.UsertotalRecords = 0;
                }
            });
        },
        getTabData(e) {
            this.userdatadisplayflag = true;
            this.clientId = e.value.value;
            this.loading = true;
            this.getClientUsersDetails();
            this.getClientKarykartaDetails();
        },
        onPageUser(e) {
            this.loading = true;
            this.page_no = e.page;
            this.getClientUsersDetails();
        },
        onPageKaryakartas(event) {
            this.loading = true;
            this.page_no = event.page;
            this.getClientKarykartaDetails();
        },
    },
};
</script>
<style scoped lang="scss"></style>
